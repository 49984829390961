// Here you can add other styles
.offcanvas-fullscreen {
  width: 50vw !important;
  max-width: 50vw !important;
  height: 100% !important;
}

.nav-group-items {
  background: #3f415f;
}

@media (max-width: 576px) {
  .offcanvas-fullscreen {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
  }
}

.offcanvas-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.offcanvas-close-button {
  padding: 0;
  margin: 0;
}

input[type="tel"] {
  width: 100%;
  height: 56px !important;
}
.dark input[type="tel"] {
  background: none;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.react-international-phone-country-selector-button {
  width: 60px;
  height: 100% !important;
}
.dark .react-international-phone-country-selector-button {
  background: none;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Customize all buttons */
.fc-button {
  background-color: #30394e !important; /* Button background color */
  color: white !important; /* Text color */
  border: none !important; /* Remove border */
  border-radius: 4px !important; /* Optional rounded corners */
}

/* Customize buttons on hover */
.fc-button:hover {
  background-color: #505a73 !important; /* Slightly lighter shade for hover effect */
  color: white !important;
}

/* Customize active (pressed) buttons */
.fc-button:active {
  background-color: #202636 !important; /* Darker shade for pressed effect */
  color: white !important;
}

/* Customize "today" button */
.fc-today-button {
  background-color: #30394e !important; /* Same as other buttons */
  color: white !important;
  border: none !important; /* Remove border */
}

/* Customize "today" button on hover */
.fc-today-button:hover {
  background-color: #505a73 !important; /* Hover effect for today button */
}

/* Customize "previous" and "next" buttons */
.fc-prev-button,
.fc-next-button {
  background-color: #30394e !important; /* Same as other buttons */
  color: white !important;
  border: none !important; /* Remove border */
}

/* Customize "previous" and "next" buttons on hover */
.fc-prev-button:hover,
.fc-next-button:hover {
  background-color: #505a73 !important; /* Hover effect */
}

.chakra-modal__content {
  max-width: 550px !important;
  width: 90% !important;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.errors {
  color: red;
}
.css-p58oka {
  align-items: center;
}
.btndiv button {
  color: #fff !important;
}
.btndiv .btn-danger {
  float: right;
}

.btndiv {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.offcanvas-body .btn-info {
  width: auto;
  max-width: 80px;
  color: #fff;
  margin: 10px 10px !important;
}

.border_2 {
  border: 1px solid #dbdfe6;
  border-radius: 7px;
  padding: 7px 10px;
}
.border_2_Dark {
  border: 1px solid #323a49;
  border-radius: 7px;
  padding: 7px 10px;
}

input.rc-time-picker-panel-input {
  background: none !important;
  color: #000 !important;
}

.rc-time-picker-panel-select li {
  color: #000 !important;
}
.dark-mode {
  .rc-time-picker-input {
    background-color: #323a49;
    color: #ffffff;
    border: 1px solid #323a49;
  }
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #cacaca;
}
::-webkit-scrollbar-track {
  background: #212631;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background: #ffffff;
  cursor: pointer;
}

.clickable-icon {
  cursor: pointer;
}
